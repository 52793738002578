export const LangDefaults = {
  CountryLanguage: 'English',
  localCurrency: 'USD',
  localCountryLang: 'us-en',
  countryCode: 'us',
  country: 'USA',
};

export type countryType = {
  countryName: string;
  countryLang: string;
  countryLanguage?: string;
  country: string;
  currency: string;
};

export const UseLang = (
  countryList: countryType[],
  test: boolean,
  countryTest: string
): [string, string, string, string, string] => {
  let country = '';
  let currency = '';
  let lang = LangDefaults.localCountryLang; // us-en langage by default
  let userLocale = LangDefaults.localCountryLang; // us-en langage by default
  //
  let ddoCountry = LangDefaults.countryCode;

  const ddoRetrieved = new URLSearchParams(window.location.search).get(
    'ddo_country'
  );

  if (ddoRetrieved) {
    ddoCountry = ddoRetrieved;
  } else if (test === true) {
    ddoCountry = countryTest;
  } else {
    ddoCountry = window?.digitalData?.user?.location?.country.toLowerCase();
    console.log('DDO Detected: ', ddoCountry);
    console.log('Length : ', navigator.language.length);
  }
  if (ddoCountry === null || ddoCountry === undefined) {
    ddoCountry = LangDefaults.countryCode; // Default in case it does not exists
  }

  // Look in currencies if country Lang exists
  const corrspondingCountryFetched = countryList.find((object) => {
    const lang = object.countryLang as string;
    if (lang.slice(0, 2) === ddoCountry.toLocaleLowerCase()) {
      console.log(`Detected country code: ${ddoCountry}`);
      userLocale = object.countryLang;
      return object;
    } else {
      return undefined;
    }
  });
  if (corrspondingCountryFetched) {
    const fetchedLang = corrspondingCountryFetched
      ? corrspondingCountryFetched.countryLang
      : LangDefaults.localCountryLang;
    userLocale = fetchedLang;
    if (fetchedLang) {
      lang = fetchedLang;
      country = corrspondingCountryFetched.country;
      currency = corrspondingCountryFetched.currency;
    }
  } else {
    country = LangDefaults.country;
    currency = LangDefaults.localCurrency;
  }
  return [lang, userLocale, country, currency, ddoCountry];
};

export const flipLang = (language: string): string => {
  const text = language.split('-');
  if (text.length === 2) {
    return text[1].concat('-').concat(text[0]);
  }
  return language;
};

/**
 *
 * @param urxlang
 * @param path
 * @returns
 */
export const addPath = (
  urxlang: string,
  path: string,
  target: string
): string => {
  let text = path;
  if (urxlang !== LangDefaults.localCountryLang) {
    text = path.replace(`${target}`, `${urxlang}/${target}`);
    console.log(`Path to:`, text);
  }
  return text;
};
