import { ReactElement, useState, useEffect } from 'react';
import { Modal, ModalBody, Link } from 'carbon-components-react';
import './styles/thanksModal.scss';
import { segmentTracking as segmentsTracking } from '../lib/tracker';
// import { PDFDownloadLink } from '@react-pdf/renderer';
import { Launch32 } from '@carbon/icons-react';

export interface thanksTable {
  group: string;
  value: string;
}

export interface ThanksModalProperties {
  showThanksModal: boolean;
  pageTitle: string;
  object: string;
  productTitle: string;
  pageUrl: string;
  location: string;
  titleThanks: thanksTable[];
  widgetTitleThanks: thanksTable[];
  contentThanks: thanksTable[];
  contentThanks2?: thanksTable[];
  buttonThanks: thanksTable[];
  pdfExport?: boolean;
  pdfArray?: thanksTable[];
  pdfObject?: any;
  setShowThanksModal: React.Dispatch<React.SetStateAction<boolean>>;
  link?: { demo: string; demoText: string };
  executeOnContinue: boolean;
}

export const ThanksModal = ({
  showThanksModal,
  pageTitle,
  object,
  productTitle,
  pageUrl,
  location,
  titleThanks,
  widgetTitleThanks,
  contentThanks,
  contentThanks2,
  buttonThanks,
  pdfExport,
  pdfArray,
  pdfObject,
  setShowThanksModal,
  link,
  executeOnContinue,
}: ThanksModalProperties): ReactElement => {
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    setShowModal(showThanksModal);
  }, [showThanksModal]);

  const runURL = () => {
    if (link?.demo) {
      let url = link.demo;
      segmentsTracking('CTA Clicked', {
        pageTitle: pageTitle,
        object,
        resultValue: `Loaded: ${url}`,
        CTA: 'Requested an URL',
        location: 'Demo',
        action: 'Clicked',
        field: '',
      });
      setTimeout(async () => {
        window.open(url);
      }, 250);
    }
  };

  return (
    <div className="thanksModal">
      <div>
        <Modal
          open={showModal}
          modalHeading={`${titleThanks[0].value}`}
          primaryButtonText={`${buttonThanks[0].value}`}
          onRequestClose={() => {
            segmentsTracking('CTA Clicked', {
              productTitle,
              pageTitle,
              object,
              pageUrl,
              resultValue: 'CTA clicked',
              CTA: 'Feedback closed on the corner',
              location,
              action: `Thank you modal closed`,
              field: '',
            });
            setShowModal(false);
            setShowThanksModal(false);
            if (executeOnContinue) {
              runURL();
            }
          }}
          onRequestSubmit={() => {
            segmentsTracking('CTA Clicked', {
              productTitle,
              pageTitle,
              object,
              pageUrl,
              resultValue: 'CTA clicked',
              CTA: 'Feedback submitted',
              location,
              action: `Thank you modal closed`,
              field: '',
            });
            setShowModal(false);
            setShowModal(false);
            setShowThanksModal(false);
            if (executeOnContinue) {
              runURL();
            }
          }}
        >
          <ModalBody hasForm className="ModalBody">
            <div className="bx--row nam">
              <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
                {titleThanks[1].value ? (
                  <div className="mar5">
                    <span className="page_sub_heading_2">
                      {titleThanks[1].value}
                    </span>
                    <br />
                  </div>
                ) : null}
                {widgetTitleThanks[0].value ? (
                  <div>
                    <span className="page_body_2">
                      {widgetTitleThanks[0].value}
                    </span>
                    <br />
                    <br />
                  </div>
                ) : null}
                {contentThanks.map((content) => (
                  <div>
                    <span className="slide_text">{content.group}</span> : {` `}
                    <span className="slide_text">
                      <strong>{content.value}</strong>
                    </span>
                    <br />
                  </div>
                ))}
                {contentThanks2 && (
                  <div>
                    <br />
                    <br />
                    <p className="mar5">
                      <span className="page_body_2">
                        {widgetTitleThanks[1].value}
                      </span>
                    </p>
                  </div>
                )}
                {contentThanks2?.map((content) => (
                  <div>
                    <span className="slide_text">{content.group}</span> : {` `}
                    <span className="slide_text">
                      <strong>{content.value}</strong>
                    </span>
                    <br />
                  </div>
                ))}
                {link?.demo ? (
                  !executeOnContinue ? (
                    <div>
                      <Link
                        href={link.demo}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          segmentsTracking('CTA Clicked', {
                            productTitle,
                            pageTitle,
                            object,
                            pageUrl,
                            resultValue: 'CTA clicked',
                            CTA: `Accessing ${link.demo}`,
                            location,
                            action: `Leaving demo`,
                            field: '',
                          });
                        }}
                      >
                        <span className="blue-highlight">
                          {link.demoText}
                          <Launch32 className="link-load_icon" />
                        </span>
                      </Link>
                    </div>
                  ) : null
                ) : null}
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};
