import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const Languages = [
  'en',
  'es',
  'ja',
  'ja-JP',
  'de',
  'de-de',
  'es-es',
  'kr-ko',
  'ko',
  'mx',
  'es-mx',
  'es-ar',
  'ar',
  'es-bo',
  'bo',
  'es-cl',
  'cl',
  'es-co',
  'co',
  'es-cr',
  'cr',
  'es-cu',
  'cu',
  'es-ec',
  'ec',
  'es-sv',
  'sv',
  'es-gt',
  'gt',
  'es-hn',
  'hn',
  'es-ni',
  'ni',
  'es-pa',
  'pa',
  'es-py',
  'py',
  'es-pe',
  'pe',
  'es-pr',
  'pr',
  'es-do',
  'do',
  'es-uy',
  'uy',
  'es-ve',
  've',
  'ar-SA',
  'sa',
];
//const userLocale = Intl.DateTimeFormat().resolvedOptions().locale;

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: true,
    //lng: userLocale.toLowerCase(),
    //lng: navigator.language || navigator.userLanguage,
    whitelist: Languages,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
