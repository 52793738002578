import { useTranslation } from 'react-i18next';
import picture from '../../assets/landing_page_image_2.png';
import picture2 from '../../assets/PC-PictureJP.svg';
import Tile4 from '../MainPage/Tile4';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

const pageTitle = 'IBM SPSS Demo';
const userLanguage = navigator.language || navigator.userLanguage;

function MainPage({ bpName, urxlang, location }) {
  const { t } = useTranslation();
  const format = urxlang === 'de-de' || urxlang === 'de' ? 'blackSpace' : '';
  const col01 = () => {
    return(
      <>
        <div className="bx--col-lg-10">
          <div className="bx--row mar2">
            <div className="bx--col-lg-16">
              <p className={urxlang === 'sa-ar' || urxlang === 'sa' ? "type7 arabic_text":"type7"}>
                {t('landing_paragraph1.text')}
              </p>
            </div>
          </div>
          <div className="bx--row mar2">
            <div className="bx--col-lg-16">
              <p className={urxlang === 'sa-ar' || urxlang === 'sa' ? "type7 arabic_text":"type7"}>
                {t('landing_paragraph2.text')}
              </p>
            </div>
          </div>
          <div className="bx--row mar3">
            <div className="bx--col-lg-16">
              <p className={urxlang === 'sa-ar' || urxlang === 'sa' ? "type7 arabic_text":"type7"}>
                {t('landing_paragraph3.text')}
              </p>
            </div>
          </div>
        </div>    
      </>
    );
  }
  const col02 = () => {
    return(
      <>
        <div className="bx--col-lg-6 bx--col-md-4 bx--col-sm-2">
          <img
            src={userLanguage.startsWith('ja') ? picture2 : picture}
            alt="Not found"
            id="img1"
          ></img>
        </div>
      </>
    );
  }

  return (
    <div>
      <div className="bx--grid" id="main-page">
        {/* <Content id="main-area">{t('MainPage.content')}</Content> */}
        <div className="section">
          {bpName ? (
            <>
              <div className="bx--row">
                <div className="bx--col figma_top"></div>
              </div>
            </>
          ) : (
            <div className="bx--row mar-13"></div>
          )}
          <div className="bx--row mar2">
            <div className="bx--col-lg-16">
              <p className={urxlang === 'sa-ar' || urxlang === 'sa' ? "type1 arabic_text" :"type1"}>
                {t('landing_title.text')}
              </p>
            </div>
          </div>
          <div className="bx--row">
            {urxlang === 'sa-ar' || urxlang === 'sa' ? col02() : col01()}
            {urxlang === 'sa-ar' || urxlang === 'sa' ? col01() : col02()}
          </div>
          <div className="bx--row">
            <div className="bx--col-lg-5 mar4">
              <Tile4
                TileId={'tile-1'}
                Title={t('landing_tile1.text')}
                Subtitle={t('landing_tile1_subtitle.text')}
                Paragraph={t('landing_tile1_paragraph.text')}
                ButtonText={t('tile1_button.text')}
                urxLag={urxlang}
                segmentParams={{
                  pageTitle: pageTitle,
                  object: 'Demo1 button',
                  resultValue: 'Demo1 button click',
                  CTA: 'Button click track 1',
                  location: 'Demo',
                  action: 'Demo1 button clicked',
                  field: '',
                }}
                linkComponent={'/component1'.concat(location.search)}
              />
            </div>
            <div className="bx--col-lg-5 mar4">
              <Tile4
                TileId={'tile-2'}
                Title={t('landing_tile2.text')}
                Subtitle={t('landing_tile2_subtitle.text')}
                Paragraph={t('landing_tile2_paragraph.text')}
                ButtonText={t('tile2_button.text')}
                urxLag={urxlang}
                classSub={format}
                segmentParams={{
                  pageTitle: pageTitle,
                  object: 'Demo2 button',
                  resultValue: 'Demo2 button click',
                  CTA: 'Button click track 2',
                  location: 'Demo',
                  action: 'Demo2 button clicked',
                  field: '',
                }}
                linkComponent={'/component2'.concat(location.search)}
              />
            </div>
            <div className="bx--col-lg-5 mar4">
              <Tile4
                TileId={'tile-3'}
                Title={t('landing_tile3.text')}
                Subtitle={t('landing_tile3_subtitle.text')}
                Paragraph={t('landing_tile3_paragraph.text')}
                ButtonText={t('tile3_button.text')}
                urxLag={urxlang}
                segmentParams={{
                  pageTitle: pageTitle,
                  object: 'Demo3 button',
                  resultValue: 'Demo3 button click',
                  CTA: 'Button click track 3',
                  location: 'Demo',
                  action: 'Demo3 button clicked',
                  field: '',
                }}
                linkComponent={'/component3'.concat(location.search)}
              />
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col-lg-16">
              <p className="type5">{t('landing_footnote.text')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withTranslation()(withRouter(MainPage));
