import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { segmentTracking } from '../../lib/tracker';
import { Button, ButtonSet } from 'carbon-components-react';
import { ArrowLeft16, Restart32 } from '@carbon/icons-react';
import { DSH_Figma_Utils } from '../../common/figmaListener';
import { productTitle } from '../../lib/tracker';
import { getEnv } from '../../common/useEnv';
import { readBucket } from 'src/common/readCOS';
import { LangDefaults } from 'src/common/useLang';

const pageTitle = 'Derive Accurate Conclusions Module 3';

class Track3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      s1: true,
      text: 'click',
      iframeUrl: '',
      figmaKey: '',
      figmaToken: '',
    };
  }

  componentDidMount = () => {
    const localeCode = this.props.urxlang;
    const track = localeCode === 'sa-ar' || localeCode === 'sa' ? 'track1' :  'track3'; 
    console.log(` userLanguage`, this.props.urxlang, localeCode);
    getEnv().then((res) => {
      const bucket = res[1];
      this.setState({ figmaToken: res[2] });
      readBucket(bucket).then((response) => {
        console.log(` content`, response[0]);
        const figmaTrackContent = response[0][track];
        figmaTrackContent.map((obj) => {
          console.log(`Processing: `, obj.countryLang);
          if (obj.countryLang === localeCode) {
            console.log(`Found default:`, obj.countryLang);
            this.setState({
              iframeUrl: obj.emailDemoUrl,
              figmaKey: obj.figmaKey,
            });
            return obj;
          }
          return '';
        });
        if (this.state.iframeUrl.length === 0) {
          figmaTrackContent.map((obj) => {
            console.log(`Processing default: `, LangDefaults.localCountryLang);
            if (obj.countryLang === LangDefaults.localCountryLang) {
              console.log(`Found demo:`, obj.countryLang);
              this.setState({
                iframeUrl: obj.emailDemoUrl,
                figmaKey: obj.figmaKey,
              });
              return obj;
            }
            return '';
          });
        }
        DSH_Figma_Utils.init({
          figma_fileKey: this.state.figmaKey,
          figma_accessToken: this.state.figmaToken,
          metricsTracking: segmentTracking,
          clickCallback: null,
          productTitle: productTitle,
          pageTitle: pageTitle,
        });
      });
    });
  };

  render() {
    const { t /* i18n */ } = this.props;
    return (
      <div>
        <div className="bx--grid" id="content3-page">
          {/* <Content id="main-area">{t('MainPage.content')}</Content> */}
          <div className="bx--row b">
            <div className="bx--col-lg-1"></div>
            <div className="bx--col-lg-3">
              <ButtonSet>
                <Button
                  className="button-header button-header3"
                  kind="tertiary"
                  href={'/component2'.concat(this.props.location.search)}
                  renderIcon={ArrowLeft16}
                  onClick={() => {
                    segmentTracking('CTA Clicked', {
                      pageTitle: pageTitle,
                      object: 'Start track 2 button',
                      resultValue: 'CTA clicked',
                      CTA: 'Request to go to path 2 from path 3',
                      location: 'Demo',
                      action: 'Clicked',
                      field: '',
                    });
                  }}
                >
                  {t('start2.text')}
                </Button>
                <Button
                  kind="tertiary"
                  className="button-header button-header3"
                  renderIcon={Restart32}
                  href={'/'.concat(this.props.location.search)}
                  onClick={() => {
                    segmentTracking('CTA Clicked', {
                      pageTitle: pageTitle,
                      object: 'Home page',
                      resultValue: 'CTA clicked',
                      CTA: 'Request to go the home page',
                      location: 'Demo',
                      action: 'Clicked',
                      field: '',
                    });
                  }}
                >
                  {t('home.text')}
                </Button>
              </ButtonSet>
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col-lg-15 bx--col-md-7 bx--col-sm-5">
              <div className="section">
                {this.props.bpName ? (
                  <>
                    <div className="bx--row figma_top">
                      <div className="bx--col"></div>
                    </div>
                  </>
                ) : (
                  <div className="bx--row"></div>
                )}
                <div className="bx--row">
                  <iframe
                    tabIndex={'-1'}
                    onLoad={() => {
                      window.parent.focus();
                    }}
                    className="figma_frame3"
                    title="SPSS demo path 1"
                    id="figma_demo3"
                    src={this.state.iframeUrl}
                  ></iframe>
                </div>
                <div className="bx--row">
                  <div className="bx--col figma_bottom3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(Track3);
