// URX Embeded Form
// https://github.ibm.com/dgc-web-platform/widget-form

// Must be the div id where you want the form to be loaded
export const INSTANCE_ID = 'urx-form';

// The required urx form-id to be embedded
export const FORM_ID = 'urx-19774';
//export const FORM_ID = 'DEMO-cloned-wrong-url';

// The required locale must be in the following format country code-language code
export const LOCALE = 'us-en';
