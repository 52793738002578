import './App.scss';
import { useState, useEffect } from 'react';
import { Content } from 'carbon-components-react/es/components/UIShell';
import NavHeader from './components/NavHeader';
import { Route, Switch, useLocation, withRouter } from 'react-router-dom';
import MainPage from './content/MainPage';
import { createContext } from 'react';
import { useBPName } from 'src/common/useBPName';
import { UseLang, flipLang } from 'src/common/useLang';
import { UT30AX6 } from 'src/constants/defaults'; // 5000
import BusinessPartner from 'src/components/BusinessPartner/BusinessPartner';
import { useTranslation } from 'react-i18next';
import Component1 from 'src/content/Track1';
import Component2 from 'src/content/Track2';
import Component3 from 'src/content/Track3';
import countryList from 'src/data/currencies.json';
import FormModal from './components/UrxForm/FormModal';
import { isMobile } from 'react-device-detect';
import i18n from '../src/i18n';

export const TormContext = createContext(null);

function App() {
  const { bpName, logo, companyId, desc, mssp, params } = useBPName(
    UT30AX6,
    true
  );
  const [, setCurrency] = useState('');
  const [, setCountry] = useState('');
  const countryToTest = 'us';
  const test = false;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [demo, setDemo] = useState('');
  const [showImage, setShowImage] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [runningMobile] = useState(isMobile);
  const { t /* i18n */ } = useTranslation();
  const [lang, userLocale, country, currency] = UseLang(
    countryList,
    test,
    countryToTest,
    setCurrency,
    setCountry
  );
  const [urxlang, setURXlang] = useState(lang);
  const location = useLocation();
  const [infoSrc, setInfoSrc] = useState('');
  console.log(` PATH`, location.search);

  useEffect(() => {
    console.log(`Running mobile: `, runningMobile, urxlang);
    setCurrency(currency);
    setCountry(country);
    console.log(`Setting i18n: `, flipLang(lang));
    i18n.changeLanguage(flipLang(lang));
    console.log(`urxlang`, urxlang);
  }, [
    runningMobile,
    test,
    setURXlang,
    urxlang,
    currency,
    country,
    lang,
    userLocale,
  ]);

  return (
    <div className="App">
      <div className={runningMobile ? 'message-mobile' : 'demo-mobile'}>
        <p className="type2">{t('landing_text.orentation')}</p>
      </div>
      <div className={runningMobile ? 'demo-mobile' : 'demo-laptop'}>
        <NavHeader
          bpName={bpName}
          logo={logo}
          companyId={companyId}
          desc={desc}
          mssp={mssp}
          params={params}
          lang={lang}
          urxlang={urxlang}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          closeModal={closeModal}
          setCloseModal={setCloseModal}
          showImage={showImage}
          setShowImage={setShowImage}
          demo={demo}
          setDemo={setDemo}
          setInfoSrc={setInfoSrc}
        />
        {bpName ? (
          <>
            <BusinessPartner bpName={bpName} logo={logo} desc={desc} />
          </>
        ) : null}
        <Content className="app-wrapper">
          <Switch>
            <Route exact path="/">
              <MainPage
                bpName={bpName}
                urxlang={urxlang}
                logo={logo}
                companyId={companyId}
                desc={desc}
                mssp={mssp}
                params={params}
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
                closeModal={closeModal}
                setCloseModal={setCloseModal}
                showImage={showImage}
                setShowImage={setShowImage}
                demo={demo}
                setDemo={setDemo}
                location={location}
              />
            </Route>
            <Route exact path="/component1">
              <Component1
                bpName={bpName}
                urxlang={urxlang}
                location={location}
              />
            </Route>
            <Route exact path="/component2">
              <Component2
                bpName={bpName}
                urxlang={urxlang}
                location={location}
              />
            </Route>
            <Route exact path="/component3">
              <Component3
                bpName={bpName}
                urxlang={urxlang}
                location={location}
              />
            </Route>
            {/* Routes with language selection */}
            <Route exact path="/:lng">
              <MainPage
                bpName={bpName}
                urxlang={urxlang}
                logo={logo}
                companyId={companyId}
                desc={desc}
                mssp={mssp}
                params={params}
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
                closeModal={closeModal}
                setCloseModal={setCloseModal}
                showImage={showImage}
                setShowImage={setShowImage}
                demo={demo}
                setDemo={setDemo}
                location={location}
              />
            </Route>
            <Route exact path="/:lng/component1">
              <Component1
                bpName={bpName}
                urxlang={urxlang}
                location={location}
              />
            </Route>
            <Route exact path="/:lng/component2">
              <Component2
                bpName={bpName}
                urxlang={urxlang}
                location={location}
              />
            </Route>
            <Route exact path="/:lng/component3">
              <Component3
                bpName={bpName}
                urxlang={urxlang}
                location={location}
              />
            </Route>
          </Switch>
          <FormModal
            bpName={bpName}
            logo={logo}
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            infoSrc={infoSrc}
          ></FormModal>
        </Content>
      </div>
    </div>
  );
}

export default withRouter(App);
