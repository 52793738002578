import { segmentTracking } from '../../lib/tracker';
import { ClickableTile, Button } from 'carbon-components-react';
import './_tile4.scss';


export default function Tile4({TileId, Title,Subtitle,Paragraph,ButtonText, segmentParams,linkComponent, classSub, urxLag}) {
  console.log("urxLang: ", urxLag);
  return(
    <>
      <ClickableTile id={TileId} href={linkComponent} className='Tile'>
        <div className="bx--grid tileMargin">
          <div className="bx--row">
            <div className="bx--col-lg-16">
              <p className={urxLag==='sa' || urxLag==='sa-ar' ? "tile_title arabic_title" : "tile_title" }>{Title}</p>
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col-lg-16">
              <p className={`${urxLag === 'sa' || urxLag === 'sa-ar' ? "tile_subtitle arabic_subtitle" : "tile_subtitle"} ${classSub}`}>{Subtitle}</p>
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col-lg-16">
              <p className={urxLag==='sa' || urxLag==='sa-ar' ? "tile_paragraph arabic_paragraph" :"tile_paragraph" }>{Paragraph}</p>
            </div>
          </div>
          <div className="bx--row">
            <div className={urxLag==='sa' || urxLag==='sa-ar' ? "bx--col-lg-16 arabic_cta" : "bx--col-lg-16"}>
              <Button
                className="tile-button-header arabic_cta"
                kind="primary"
                onClick={() => {
                  segmentTracking('CTA Clicked', segmentParams);
                }}
              >
                {ButtonText}
              </Button>    
            </div>                
          </div>
        </div>
      </ClickableTile>
    </>
  );
}
